import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'light', // Меняем на светлую тему
    useSystemColorMode: false, // Отключаем системную настройку
  },
  colors: {
    customGreen: "#9AE6B4", // Ваш кастомный цвет, если нужно сохранить
    headerBg: "#FFF8F0",    // Светлый фон для Header
    primaryText: "#3E3E3E", // Темный текст для заголовков
    secondaryText: "#6B6B6B", // Текст для подзаголовков
    lightBackground: "#F7F7F7", // Светлый фон
    accentColor: "#E29578", // Светло-розовый акцентный цвет
  },
  styles: {
    global: {
      body: {
        bg: 'lightBackground', // Устанавливаем светлый фон для страницы
        color: 'primaryText', // Темный цвет текста
        fontFamily: 'Arial, sans-serif',
      },
      h1: {
        color: 'primaryText',
        fontSize: '24px',
      },
      h2: {
        color: 'secondaryText',
        fontSize: '20px',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      variants: {
        register: {
          bg: 'accentColor', // Цвет кнопки, как на скриншоте
          color: 'white',
        },
        outline: {
          borderColor: 'accentColor',
          color: 'accentColor',
        },
      },
    },
    Box: {
      variants: {
        header: {
          bg: 'headerBg',   // Используем светлый фон для Header
          p: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          h: "60px",
        }
      }
    }
  },
});

export default theme;


//рабочая dark тема
/*import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  colors: {
    customGreen: "#9AE6B4", // Custom color
    headerBg: "#242F3D",   // Добавляем цвет фона для компонента Header
  },
  styles: {
    global: {
      body: {
        bg: 'gray.800',
        color: 'white',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      variants: {
        register: {
          bg: 'yellow.400',
          color: 'black',
        },
      },
    },
    Box: {
      variants: {
        header: {
          bg: 'headerBg',   // Используем цвет, добавленный выше
          p: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          h: "60px",
        }
      }
    }
  },
});

export default theme;


*/


/*import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  colors: {
    customGreen: "#9AE6B4", // Custom color
  },
  styles: {
    global: {
      body: {
        bg: 'gray.800',
        color: 'white',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
      },
      variants: {
        register: {
          bg: 'yellow.400',
          color: 'black',
        },
      },
    },
  },
});

export default theme;*/
